import React, {useState,useEffect} from "react";
import {useLocation, useNavigate, useParams} from "react-router";
import "./Orders.scss";
import {Button, Header, Card, Dropdown} from "Components";
import {formatDate} from "Utils/TextFormat";
import {formatCurrency} from "Utils/TextFormat";
import {axiosWithAuth} from "Utils/axiosWithAuth";

function Order() {

	const {id} = useParams();
	const {state} = useLocation();
	const [order,setOrder] = useState(null);
	const navigate = useNavigate();

	useEffect(()=>{
		if(state){
			setOrder(state);
		}else{
			fetchOrder(id);
		}
	},[state]);

	const fetchOrder = (id) => {
		axiosWithAuth().get(`store/orders/${id}`).then(res=>{
			setOrder(res.data)
		}).catch(err => {
			if(err?.response?.status === 500){
				alert("Se produjo un error al cargar la orden. Por favor, inténtalo de nuevo más tarde");
			}	
			console.log(err);
		})
		
	}

	const retryPayment = (paymentMethod) => {
		// TODO - create a context for this
		axiosWithAuth().get(`store/orders/${id}/retry`,{
			params:{
				paymentMethod
			}
		}).then(res=>{
			if(res.status === 201){
				window.location.href = res.data.paymentUrl;
			}
		}).catch(err => {
			if(err?.response?.status === 409){
				alert("El pago ya ha sido realizado.");			
				// TODO - create an alert to show to the user the payment was already paid
				window.location.reload();
				// navigate(`success`, {state:order})
			}
			else if(err?.response?.status === 400){
				alert("La orden ya ha sido completada.");							
			}
			else if(err?.response?.status === 500){
				alert("Se produjo un error al reintentar el pago. Por favor, inténtalo de nuevo más tarde");
			}else{
				console.log(err);
			}

		})	
	}

	if(!order){
		return <div className="loading">Cargando detalles</div>
	}

	return (
		<Card className="Order">
			{<Header title={order.orderId} alignText={"center"}/>}
			
			<div className="Order-info">

				<div className="row nowrap">
					<p className="key">Nombre:</p>
					<p className="value">{order.user.fullName}</p>
				</div>
				<div className="row nowrap">
					<p className="key">Correo Electronico:</p>
					<p className="value">{order.user.email}</p>
				</div>
				{order.user.abbasoonCode && 
					<div className="row nowrap">
						<p className="key">Abbasoon Code:</p>
						<p className="value">{order.user.referrerId}</p>
					</div>
				}

				<div className="row nowrap">
					<p className="key">Codigo de referido:</p>
					<p className="value">{order.user.fullName}</p>
				</div>
				<div className="row nowrap">
					<p className="key">Fecha de pago:</p>
					<p className="value">{formatDate(order.payment.completedAt)}</p>
				</div>
				{/* <div className="row nowrap"> Check if necesary
					<p className="key">Codigo de cupon:</p>
					<p className="value">{}</p>
				</div> */}
				<div className="row nowrap"> 
					<p className="key">Total:</p>
					<p className="value">{formatCurrency(order.products.reduce((curr,acc) => acc.price * acc.quantity + curr, 0))}</p>
				</div>
				<div className="row nowrap"> 
					<p className="key">Metodo de pago:</p>
					<p className="value">{order.payment.type}</p>
				</div>
				<div className="row nowrap"> 
					<p className="key">Detalle de compra:</p>
					<div className="value-wrapper">
						{order.products.map(p => <p key={p.name}>
							{p.name} (x{p.quantity}) {formatCurrency(p.price)}
						</p>)}
					</div>
				</div>
			</div>

			<div className="row nowrap gp-1 actions">
				
				
				<Button
					variant="outlined"
					theme="secondary"
					action={()=>{navigate("/profile/orders")}}
				>Volver</Button>
				
				{order.status == "completed" ? 
					null
					// <Button
					// 	theme="secondary"
					// >
					// 	Descargar factura
					// </Button>
				: 
					// Todo we can create a common component for this
					<Dropdown
						title={"Volver a intentar"}
					>
						<Dropdown.Item
							action={()=>{retryPayment("Wallet")}}
						>
							Wallet {order?.payment.type === "Wallet" && "(Original)"}
						</Dropdown.Item>
						<Dropdown.Item
							action={()=>{retryPayment("Stripe")}}
						>
							Stripe {order?.payment.type === "Stripe" && "(Original)"}
						</Dropdown.Item>
					</Dropdown>
				}
			</div>
		</Card>
	);
}

export default Order;
