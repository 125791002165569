import React, {useState} from "react";
import {Button, Card} from "Components";
import {Link} from "react-router-dom";
import contacts from "Utils/contacts";
import "../Service.scss";


const imageLinks = [
	"https://abbasunrise-dev-bucket.s3.amazonaws.com/StoreAssets/AbbaFreedom/slide_1.png",
	"https://abbasunrise-dev-bucket.s3.amazonaws.com/StoreAssets/AbbaFreedom/slide_2.png",
	"https://abbasunrise-dev-bucket.s3.amazonaws.com/StoreAssets/AbbaFreedom/slide_3.png",
	"https://abbasunrise-dev-bucket.s3.amazonaws.com/StoreAssets/AbbaFreedom/slide_4.png",
	"https://abbasunrise-dev-bucket.s3.amazonaws.com/StoreAssets/AbbaFreedom/slide_5.png",
	"https://abbasunrise-dev-bucket.s3.amazonaws.com/StoreAssets/AbbaFreedom/slide_6.png"
]


function Health() {

	const [contact] = useState(contacts.filter(c => c.key === "doc")[0])

	return (
		<div className="Service imagePage Health">
			<div className="content">
				{imageLinks.map(link => <div className="img-container" key={link}>
						<img src={link}/> 
					</div>
				)}
			</div>

			<div className="bottomWrapper">
				<Card.Contact name={contact.name} methods={contact.methods} whatsapp={contact.whatsapp} />

				<Link
					to="/products"
				>
					<Button
						theme="secondary"
						className="buyNow"
					>
						Comprar ahora
					</Button>
				</Link>

			</div>
			
		</div>
	);
}

export default Health;