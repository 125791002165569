import React, {useState, useEffect, useContext} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Header,InputField,Checkbox,Button, Card } from "Components";
import "./Login.scss";
import {LoginLogo} from "assets";
import { useAuth, useAuthLogin } from "Context/Auth";
import {useTranslation} from "react-i18next";

function Login() {

	const [lUserData,setLUserData] = useState({
		email:'',
		password:''
	});
	const [showPassword,setShowPassword] = useState(false);
	const [emailError,setEmailError] = useState(null);
	const [passwordError,setPasswordError] = useState(null);
	const login = useAuthLogin();
	const userData = useAuth();
	const navigate = useNavigate();
	const { t } = useTranslation();
	
	const handleLogin = (e) => {
		e.preventDefault();
		login(lUserData);
	}

	useEffect(()=>{
		if(userData.uuid){
			navigate("/")
		}
	},[userData])

	return (
		<div className="Login">
			
			<div className="Login-wrapper">
				<Header title={t("login")} alignText={"center"}/>
				<Card>
					<form action="login" onSubmit={handleLogin}>
						<div className="inputs">
							<InputField 
								name="email"
								value={lUserData.email}
								setValue={(nVal)=> setLUserData({...lUserData, email:nVal})}
								label={t("email")}
								error={emailError}
							/>
							<InputField 
								name="password"
								value={lUserData.password}
								setValue={(nVal)=> setLUserData({...lUserData, password:nVal})}
								label={t("password")}
								type={showPassword?"text":"password"}
								error={passwordError}
							/>
						</div>

						<div className="actions">
							<Checkbox variant="round" value={showPassword} toggleValue={setShowPassword} label={t("showPassword")}/>
							<Link to={process.env.REACT_APP_MAIN_URL}>
								{t("forgotPassword")}
							</Link>
						</div>

						<Button className="login" variant="secondary" type="submit">
						{t("login")}
						</Button>
					</form>
				</Card>
			</div>


		</div>
	
	);
}

export default Login;