import {axiosWithAuth} from "Utils/axiosWithAuth";
import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router";
import {useSearchParams} from "react-router-dom";

function Referral() {

	const {referrerCode} = useParams();
	const navigate = useNavigate();

	useEffect(()=>{
		if(referrerCode){
			validateReferrerCode(referrerCode);
		}
	},[referrerCode]);

	const validateReferrerCode = () => {
		axiosWithAuth().post("auth/validateRefcode", {
			code: referrerCode
		}).then(res => {
			if(res.status === 200){
				localStorage.setItem("storeRefCode", referrerCode.toUpperCase());
			}
		}).catch(err => {
			console.log(err);
			alert("Parece que ese codigo de referido no esta registrado en la wallet")
		}).finally(()=>{
			navigate("/products")
		})
	}
	
	return null;
}

export default Referral;