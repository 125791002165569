import React from "react";
import Card from "Components/Card/Card";
import { formatCurrency } from "Utils/TextFormat";
import "./ProductsTotalCard.scss";

function ProductsTotalCard({products,children,header}) {

	return (
		<Card className="ProductsTotal">
		
			{header}
			
			<div className="products-wrapper">
				{products.map(p => <div key={p.id} className="row product">
					<p className="key">{p.name} (x{p.quantity})</p>
					<p className="value">{formatCurrency(p.price)}</p>
				</div>
				)}

			</div>
			

			<div className="row total">
				<p className="key">Total:</p>
				<p className="value">{
					formatCurrency(products.reduce((acc,curr) => (curr.price * curr.quantity) + acc, 0))
				}</p>
			</div>

			<div className="slot-wrapper">
				{children}
			</div>
		</Card>
	);
}

export default ProductsTotalCard;