import Header from "./Header/Header"
import Navigation from "./Navigation/Navigation"
import Button from "./Button/Button"
import InputField from "./InputField/InputField"
import Checkbox from "./Checkbox/Checkbox"
import Confirmation from "./Confirmation/Confirmation"
import Modal from "./Modal/Modal"
import Card from "./Card/Card"
import Footer from "./Footer/Footer"
import Dropdown from "./Dropdown/Dropdown" 
import Table from "./Table/Table" 
import QuantityButton  from "./Common/QuantityButton/QuantityButton";
import BillingAddressCard from "./Common/BillingAddressCard/BillingAddressCard"
import ProductsTotalCard from "./Common/ProductsTotalCard/ProductsTotalCard"
import ContactCard from "./Common/ContactCard/ContactCard"

// Define aliases
Button.ProductQuantity = QuantityButton;
Card.BillingAddress = BillingAddressCard;
Card.ProductsTotal = ProductsTotalCard;
Card.Contact = ContactCard;

// Export
export {
	Header,
	Navigation,
	Button,
	InputField,
	Checkbox,
	Confirmation,
	Modal,
	Card,
	Footer,
	Dropdown,
	Table
}