import React from "react";
import classNames from "classnames";
import "./Table.scss";

const Table = ({className,columns,children,growVertical,headers}) => {
	return (
		<div className={classNames("Table", className, {growVertical})}>
			<table>
				<thead>
					<tr>
						{headers || columns.map(c => <th key={c}>{c}</th>)}
					</tr>
				</thead>

				<tbody>
					{children}
				</tbody>
			</table>
		</div>
	);
}

Table.Row = ({className,children,action}) => {
	return (
		<tr 
			onClick={action}
			className={classNames("Row", className)}
		>
			{children}
		</tr>
	)
}

Table.RowItem = ({className,children,action,colSpan}) => {
	return <td 
		className={classNames("RowItem", className)}
		onClick={action}
		colSpan={colSpan}
	>	
		{children}
	</td>
}

export default Table;