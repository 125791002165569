import React, {useEffect, useRef, useState} from "react";
import "./Dropdown.scss";
import {ArrowIcon} from "assets";
import classNames from "classnames";

const Dropdown = ({variant="primary",title,children,className=""}) => {
	
	const [isExpanded,setIsExpanded] = useState(false);
	const dropDownRef = useRef(null);

	useEffect(()=>{
		function handleMouseDown(event){
			if((dropDownRef?.current.contains(event.target) ||
				dropDownRef?.current.querySelector(".Dropdown-content-wrapper")?.contains(event.target))
			){
				return;
			}

			setIsExpanded(false);
		}

		document.addEventListener("mousedown", handleMouseDown);
		return () => { document.removeEventListener("mousedown", handleMouseDown) }
	},[dropDownRef]);

	const toggleDropDownMenu = (e) => {
		e.stopPropagation();
		setIsExpanded(!isExpanded);
	}


	return (
		<div className={classNames("Dropdown",variant,className,{expanded:isExpanded})} ref={dropDownRef}>
			
			<div className="Dropdown-button" onClick={toggleDropDownMenu}>
				<p>{title}</p> 
				<span className="icon">
					<ArrowIcon/>
				</span>
			</div>


			{isExpanded?
				<div className="Dropdown-content-wrapper">
					<div className="content">
						{children}
					</div>
				</div>
			:null}
			
		</div>
	);
}

Dropdown.Item = ({children,action,className="",selected}) => {
	return (<div className={classNames("DropdownItem",className,{selected})} onClick={action}>
		<p>{children}</p>
	</div>)
}

export default Dropdown;