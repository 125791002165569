import React,{useState,useEffect} from "react";
import {Header,Button, Dropdown} from "Components";
import {Spinner, StatusIcon} from "assets";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {useNavigate, useParams} from "react-router";
import Order from "Views/Profile/Orders/Order";
import "./OrderStatus.scss";
import {useAuth} from "Context/Auth";

function OrderStatus() {

	const [order,setOrder] = useState(null);
	const [isLoading,setIsLoading] = useState(false);
	const {id} = useParams();
	const navigate = useNavigate();
	const userData = useAuth();


	useEffect(()=>{
		if(id){
			fetchOrder(id);
		}
	},[id]);

	const fetchOrder = (id) => {
		setIsLoading(true);
		axiosWithAuth().get(`store/orders/${id}`).then(res=>{
			setOrder(res.data);
		}).catch(err => {
			console.log(err);
			if(err?.response?.status === 500){
				alert("Se produjo un error al cargar la orden. Por favor, inténtalo de nuevo más tarde");
			}			
		}).finally(_ => {
			setIsLoading(false);
		})
	}

	const retryPayment = (paymentMethod) => {
		// TODO - create a context for this
		axiosWithAuth().get(`store/orders/${id}/retry`,{
			params:{
				paymentMethod
			}
		}).then(res=>{
			if(res.status === 201){
				if(userData?.uuid){
					window.location.href = res.data.paymentUrl;
				}else{
					window.location.reload();
				}
			}
		}).catch(err => {
			if(err?.response?.status === 409){
				alert("El pago ya ha sido realizado.");			
				// TODO - create an alert to show to the user the payment was already paid
				window.location.reload();
				// navigate(`success`, {state:order})
			}
			else if(err?.response?.status === 400){
				alert("La orden ya ha sido completada.");							
			}
			else if(err?.response?.status === 500){
				alert("Se produjo un error al reintentar el pago. Por favor, inténtalo de nuevo más tarde");
			}else{
				console.log(err);
			}

		})	
	}


	const renderStatusPage = () => {
		if(order.status==="completed"){
			return <Success/>
		}else if(order.status==="pending"){
			return <Pending retryPayment={retryPayment} navigate={navigate} order={order}/>
		}
	}

	if(!order){
		return <Spinner/>
	}

	return (
		<div className="OrderStatus">
			<div className="wrapper">
				{isLoading?
					<Spinner/>
					:renderStatusPage()
				}
			</div>
		</div>
	);
}

export default OrderStatus;


function Success() {
	return (<>
		<div className="topInfo">
			<StatusIcon.Success/>
			<Header title="Orden realizada con exito"/>
		</div>
		<Order/>
	</>
	);
}


function Failure({retryPayment,navigate,order}) {

	const [paymentMethod,setPaymentMethod] = useState(order?.payment.type);

	return (
		<>
			<div className="topInfo">
				<StatusIcon.Failure/>
				<Header title="Orden denegada"/>
			</div>
			<div className="bottomInfo">
				<p className="reason">
					Link a caducado
				</p>
				<div className="actions">
					<Button
						action={()=>{navigate("/profile/orders")}}
						variant="outlined"
						theme="secondary"
					>
						Ver ordenes
					</Button>

					<Dropdown
						title={"Volver a intentar"}
					>
						<Dropdown.Item
							action={()=>{retryPayment("Wallet")}}
						>
							Wallet {order?.payment.type === "Wallet" && "(Original)"}
						</Dropdown.Item>
						<Dropdown.Item
							action={()=>{retryPayment("Stripe")}}
						>
							Stripe {order?.payment.type === "Stripe" && "(Original)"}
						</Dropdown.Item>
					</Dropdown>
				</div>
			</div>
		</>
	);
}


function Pending({retryPayment,navigate,order}){
	return (
		<>
			<div className="topInfo">
				<Header title="Orden pendiente"/>
			</div>
			<div className="bottomInfo">
				<p className="reason">
					La orden aun esta pendiente de ser completada.
				</p>

				{order.payment.type === "Wallet" && <>
					<div className="disclaimer">
						Para finalizar el pago con tu metodo de pago solicitado necesitaras una cuenta de FINTECH. Si no tienes una puedes compartir el enlace con un usuario que tenga una cuenta de finech o en su defecto cambiar el metodo de pago a stripe para pagar con tu tajeta de credito/debito.
					</div>
					<div className="link">
						{order.paymentUrl}
					</div>
				
				</>}

				<div className="actions">
					<Button
						action={()=>{window.open(order.paymentUrl, "_blank")}}
						variant="outlined"
						theme="secondary"
					>
						Completar pago
					</Button>

					<Dropdown
						title={"Volver a intentar"}
					>
						<Dropdown.Item
							action={()=>{retryPayment("Wallet")}}
						>
							Wallet {order?.payment.type === "Wallet" && "(Original)"}
						</Dropdown.Item>
						<Dropdown.Item
							action={()=>{retryPayment("Stripe")}}
						>
							Stripe {order?.payment.type === "Stripe" && "(Original)"}
						</Dropdown.Item>
					</Dropdown>
				</div>
			</div>
		</>
	);
}