import React,{ useState, useEffect } from "react";
import "./QuantityButton.scss";
import { useCartActions } from "Context/Cart";
import { Button, Card } from "Components";

// This is a reusable component for buttonGroup that contains add an remove quantity of a product
// Instead of adding it to the button component I prefered to add it here for cleaner css and imports


function QuantityButton({product}){

	const [newQuantity,setNewQuantity] = useState(product.quantity);
	const {deleteItem, setQuantity} = useCartActions();

	useEffect(()=>{
		if(newQuantity === '') return

		if(Number(newQuantity) <= 0){
			deleteProduct();
		}else{
			setQuantity(product.id, newQuantity)
		}
	},[newQuantity]);

	const deleteProduct = () => {
		if(window.confirm("Retirar del carrito")){
			deleteItem(product.id)
		}else{
			setNewQuantity(1)
		}
	}

	return <Card.Secondary row centered className={"QuantityButton"}>
		<Button
			variant="bare"
			theme="secondary"
			action={()=>{setNewQuantity(product.quantity-1)}}
		>-</Button>
		<p className="quantity">
			{product.quantity}
		</p>
		<Button
			variant="bare"
			theme="secondary"
			action={()=>{setNewQuantity(product.quantity+1)}}
		>+</Button>
	</Card.Secondary>
}

export default QuantityButton;