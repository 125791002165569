import Card from "Components/Card/Card";
import Header from "Components/Header/Header";
import {Social} from "assets";
import React from "react";
import {Link} from "react-router-dom";
import "./ContactCard.scss";
import {useTranslation} from "react-i18next";

export default function ContactCard({name,methods,whatsapp}){
	const {t} = useTranslation();
	return (<Card className={"contact"}>
		<div className="cardHeader">
			<Header title={name}/>
		</div>
		<div className="methods">
			{methods.map(method => <div key={name+method.value} className="method">
				<p className="title">
					{t(method.title)}
				</p>
				<p className="value">
					{method.value}
				</p>
			</div>)}
		</div>
		<div className="contactNow">
			
				<Link
					to={`https://wa.me/${whatsapp}`}
					target={"_blank"}
					className="whatsappLink"
				>
					<Social.Whatsapp/>
					<p>
						{t("contactNow")}
					</p>
				</Link>
		
		</div>


	</Card>)

}