import React from "react";
import {Button, Card} from "Components";
import "./BillingAddressCard.scss";
import classNames from "classnames";

function BillingAddressCard({
	selectBillingAddress,
	billingAddress,
	setEditAddress,
	selected=false,
	noControls=false
}) {

	const copyAddress = () => {
		const text = `
			${billingAddress.street}
			${billingAddress.city}
			${billingAddress.state}
			${billingAddress.country}
			${billingAddress.zip}
		`;

		if(navigator.clipboard){
			// Navigator clipboard is not available on unsecured origins
			navigator.clipboard.writeText(text);
			return;
		}

		const textArea = document.createElement('textarea');
		textArea.value = text;
		textArea.style.position = 'fixed';
		textArea.style.opacity = 0;

		document.body.appendChild(textArea);
		textArea.select();

		try {
			const success = document.execCommand('copy');
			if(!success){
				throw "Cannot copy";
			}
		} catch (error) {
			console.error("Cannot copy to clipboard")
		} finally {
			document.body.removeChild(textArea);
		}
	}


	return (<Card.Secondary className={classNames("billingAddress",{selected})}>
			<p>{billingAddress.street}</p>
			<p>{billingAddress.city}</p>
			<p>{billingAddress.state}</p>
			<p>{billingAddress.country}</p>
			<p>{billingAddress.zip}</p>

			
			{!noControls?
				<div className="controls">
					<div className="row nowrap gp-1">
						<Button 
							variant="outlined" 
							theme="secondary" 
							className="edit"
							action={()=>{setEditAddress(billingAddress)}}
						>Editar</Button>
						<Button 
							variant="outlined" 
							theme="secondary" 
							className="edit"
							action={() => {copyAddress()}}
						>Copiar</Button>
					</div>
					<Button 
						variant="filled" theme="secondary" className="edit"
						action={() => {selectBillingAddress(billingAddress)}}
					>Seleccionar</Button>
				</div>
			:null}
		</Card.Secondary>
	)
}

export default BillingAddressCard;


