import React from "react";
import "./InputField.scss"
import classNames from "classnames";

function InputField({
	value,
	setValue,
	name,
	type="text",
	showPassword,
	label,
	error,
	minLength,
	maxLength,
	className="",
	max,
	min,
	pattern,
	readOnly,
	required,
	step,
	placeholder,
	onChange,
	autoComplete=true,
	inputMode,
	slot,
	inLineSlot,
	fitContent=false
}) {

	const handleChange = (e) => {
		e.preventDefault();
		if(onChange){
			onChange(e);
		}else{
			setValue(e.target.value)
		}
	}
	
	return (
		<div className={classNames('input-wrapper',className,{fitContent})}>
			
			
			{label?<div className="label">
				{label}  
				{error?<span className="error"> - {error}</span>:null}
			</div>:null}

			<div className={classNames("input-field",{error,hasSlot:slot || inLineSlot})}>
				<input
					name={name} 
					type={ 	type === "password"?showPassword?"number":"password":type} 
					value={value} 
					onChange={handleChange}
					minLength={minLength}
					maxLength={maxLength}
					max={max}
					min={min}
					pattern={pattern}
					inputMode={inputMode}
					readOnly={readOnly}
					required={required}
					step={step}
					placeholder={placeholder}
					autoComplete={autoComplete?null:"new-off"}
				/>

				{(inLineSlot || slot) && <div className={classNames("slotWrapper",{inline:inLineSlot})}>
					{inLineSlot || slot}
				</div>}
			</div>
		</div>
	);
}

export default InputField;