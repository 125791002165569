import React from "react";
import "./Card.scss";
import classNames from "classnames";

const Card = ({height,width,className,children,variant="primary",row=false,centered=false,action}) => {
	return (
		<div className={classNames("Card", variant, className, { row, centered })}
			style={{
				height:height,
				width:width
			}}
			onClick={action}
		>
			{children}
		</div>
	);
}

Card.Secondary = (props) => {
	return (
		<Card
			variant="secondary"
			{...props}
		/>
	);
}

export default Card;

