import {Button, Card, Header} from "Components";
import React from "react";
import "./Contact.scss";
import {Link} from "react-router-dom";
import {Social} from "assets";
import contacts from "Utils/contacts";
import {useTranslation} from "react-i18next";

function Contact() {

	const {t} = useTranslation();

	return (
		<div className="Contact">
			<Header title={t("contact")}/>
			<div className="contacts-wrapper">
				{contacts.map(contact => {
					return <Card.Contact key={contact.name} name={contact.name} methods={contact.methods} whatsapp={contact.whatsapp} />
				})}
			</div>
		</div>
	);
}

export default Contact;
