import React from "react";
import "./Header.scss";

function Header({title,subtitle,alignText}) {
	return (
		<div className="Header-wrapper" style={{textAlign:alignText}}>
			<h1 className="title">{title}</h1>
			<p className="subtitle">{subtitle}</p>
		</div>
	);
}

export default Header;