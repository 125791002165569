export function formatCurrency(value){
	return parseFloat(value).toLocaleString('us', {
		style: 'currency',
		currency: 'USD'
	})
}


/**
 * @param {date} value 
 * @param {"dateOnly" | "timeOnly"} [type=DateTime]
 * @returns {string} Formated date
 */
export function formatDate(value,type){
	if(type === "dateOnly"){
		return new Date(value).toLocaleDateString()
	}else if(type === "timeOnly"){
		return new Date(value).toLocaleTimeString()
	}

	return new Date(value).toLocaleString()
}