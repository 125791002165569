import {Header} from "Components";
import ProductPreview from "Components/ProductPreview/ProductPreview";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import React, {useEffect, useState} from "react";
import "./Products.scss";
import {Dropdown} from "Components";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Products() {

	// Products
	const [products, setProducts] = useState([]);
	const { t } = useTranslation();

	useEffect(()=>{
		fetchProducts();
	},[]);

	const fetchProducts = () => {
		axiosWithAuth().get("/products/fg").then(res => {
			if(res.status === 200){
				setProducts(res.data);
			}
		}).catch(err=>{
			if(err?.response?.status === 500){
				alert("Se produjo un error al obtener los productos. Por favor, inténtalo de nuevo más tarde");
			}
			console.log(err);
		})
	}

	return (
		<div className="Products">
			<Header title={t('title')}/>


			<div className="controls">
				<p>{t('showingResults')}</p>
				{/* <Dropdown title="Ordenar Por"> TODO
					<Dropdown.Item>
						Precio
					</Dropdown.Item>
					<Dropdown.Item>
						Fecha
					</Dropdown.Item>
				</Dropdown> */}
			</div>

			<div className="Products-wrapper">
				{products.map(p => 
				<Link 
					key={p.id} 
					to={"product/"+p.id} 
					state={p}>
					<ProductPreview 
						name={p.name} 
						description={p.description}
						price={p.price} 
						id={p.id}
						imageUrl={p.imageUrl}
						/>
					</Link>
				)}
			</div>

		</div>
	);
}

export default Products;