import {Button, Card, Header, InputField} from "Components";
import {useAuth, useAuthLogout} from "Context/Auth";
import {AvatarIllustration} from "assets";
import React from "react";
import {Route, Routes, useLocation} from "react-router";
import {Link, NavLink} from "react-router-dom";
import Orders from "./Orders/Orders";
import classNames from "classnames";
import Payments from "./Payments/Payments";
import "./Profile.scss";
import Order from "./Orders/Order";
import {useTranslation} from "react-i18next";

function Profile() {

	const location = useLocation();
	const {t} = useTranslation();

	return (
		<div className="Profile">
			<div className="content">
				<div className="row cards-wrapper">
					<Card className={"historyWrapper"}>

						<div className="historyNavigation">
							<Link
								to="/profile"
								className={classNames("Link",{active:location.pathname === "/profile"})}
							>
								{t("profile")}
							</Link>
							
							<NavLink 
								// Using Link because active class wont be removed when visiting subroutes
								to="/profile/orders" 
								className="Link"
								
							>
								{t("orders")}
							</NavLink>
							{/* <NavLink to="/profile/payments" className={"Link"}>
								Pagos
							</NavLink> */}
						</div>

						<Routes>
							<Route
								exact path="/"
								element={<PersonalDetails/>}
							/>
							<Route
								path="/orders/"
								element={<Orders/>}
							/>
							<Route
								path="/orders/:id"
								element={<Order/>}
							/>
							{/* <Route
								path="/payments"
								element={<Payments/>}
							/> */}
						</Routes>
					</Card>
				</div>
			</div>
		</div>
	);
}

export default Profile;

const PersonalDetails = () => {
	
	const user = useAuth();	
	const {t} = useTranslation();
	const logout = useAuthLogout();
	const handleLogout = () => {
		// Confirm
		logout();
	}

	const copyReferralLink = async () => {
		const referralLink = `${window.location.origin}/referrer/${user.abbasoonCode}`;
		if("clipboard" in navigator) {
			await navigator.clipboard.writeText(referralLink);
			alert(t("copySuccess"))
		}else{
			alert(referralLink)
		}
	}

	return <div className="UserDetails">
		<AvatarIllustration/>
		<InputField
			label={t("name")}
			value={user.fullName}
			readOnly
		/>
		<div className="row nowrap gp-1">
			<InputField
				label={t("email")}
				value={user.email}
				readOnly
			/>
			<InputField
				label={t("phone")}
				value={user.phone}
				readOnly
			/>
		</div>
		

		<InputField
			label={t("abbasoonCode")}
			value={user.abbasoonCode}
			readOnly
			slot={<Button
			theme="secondary"
				action={copyReferralLink}
			>
				{t("copy")}
			</Button>}
		/>

		<div className="row nowrap gp-1">
			<InputField
				label={t("referrerCode")}
				value={user.referrerId}
				readOnly
			/>
		</div>

		<InputField
			label={t("gender")}
			value={t(`genders.${user.gender}`)}
			readOnly
		/>

		<Button 
			className="logout" 
			theme="secondary"
			action={handleLogout}
		>
			{t("disconnect")}
		</Button>
	</div>
}