import React from "react";
import "./Button.scss";
import classNames from "classnames";

const Button = ({
	className="",
	variant="filled",
	theme="primary",
	children,
	action,
	type="button",
	disabled}) => {
	return (
		<button 
			className={classNames("Button",variant,theme,className)}
			onClick={action} 
			type={type} 
			disabled={disabled}
		>
			{children}
		</button>
	);
}

export default Button;