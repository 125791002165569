export default [
	{	
		key:"sup1",
		name:"Soporte tecnico abbasunrise 1",
		methods:[
			{
				"type":"email",
				"title":"email",
				"value": "Soporte@abbasunrise.org"
			},
			{
				"type":"phone",
				"title":"phone",
				"value": "+503 7738 4476"
			}
		],
		whatsapp: "50377384476"
	},
	{	
		key: "doc",
		name:"Zend Health Care",
		methods:[
			{
				"type":"phone",
				"title":"phone",
				"value": "+52 5532234876"
			}
		],
		whatsapp: "525532234876"
	}
]