import {Button, Card, Dropdown, Header, InputField, Table} from "Components";
import {useAuth} from "Context/Auth";
import {useCart} from "Context/Cart";
import {formatCurrency} from "Utils/TextFormat";
import React, {useState,useEffect} from "react";
import "./Checkout.scss";
import {axiosWithAuth} from "Utils/axiosWithAuth";
import {useNavigate} from "react-router";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Checkout(){
	const cart = useCart();
	const navigate = useNavigate();
	const [coupon,setCoupon] = useState("");
	const {t} = useTranslation();

	if(Object.values(cart).length === 0){
		return (
		<div className="Checkout">
			{/* Nono */}
			<div className="wrapper">
				<p>{t("noProducts")}</p>
				<NavLink to="/products">
					{t("checkProductsHere")}
				</NavLink>
			</div>
		</div>
		)
	}

	return (
		<div className="Checkout">

			<div className="wrapper">
				<Header
					title={t("orderSummary")}
					alignText={"center"}
				/>
				<Card
					centered
					className={"tableWrapper"}
				>
					<Table
						columns={[
							t("quantity"),
							t("product"),
							t("price"),
							t("total")
						]}
					>
						{Object.values(cart).map(product => <Table.Row key={product.id}>
							<Table.RowItem>
								<div className="quantity">
									<Button.ProductQuantity
										product={product}
									/>
								</div>
							</Table.RowItem>
							<Table.RowItem>
								{product.name}
							</Table.RowItem>
							<Table.RowItem>
								{formatCurrency(product.price)}
							</Table.RowItem>
							<Table.RowItem>
								{formatCurrency(product.price * product.quantity)}
							</Table.RowItem>
						</Table.Row>)}
					</Table>

					<div className="actions">
						
						<div className="inputsWrapper">
							<InputField
								value={coupon}
								setValue={setCoupon}
								name={coupon}
								placeholder={t("addCoupon")}
								fitContent
							/>
							<InputField
								value={`${t("subtotal")} ${formatCurrency(Object.values(cart).reduce((acc,curr) => curr.price * curr.quantity + acc,0))}`}
								setValue={setCoupon}
								name={coupon}
								fitContent
							/>

						</div>
				
						<Button
							variant="outlined"
							theme="secondary"
							action={() => {navigate("/products/")}}
						>
							{t("buyMore")}
						</Button>
						<Button
							theme="secondary"
							action={() => {navigate("/payment/")}}
						>
							{t("payNow")}
						</Button>
					</div>


				</Card>
			</div>

		
			

		</div>
	)
}

export default Checkout;
