import {useAuth} from "Context/Auth";
import React from "react";
import { Navigate } from "react-router";

function PrivateRoute({children}) {
	
	const userData = useAuth();

	if(userData.uuid){
		return children
	}else{
		return <Navigate to="/login"/>
	}
}

export default PrivateRoute;