import React from "react";
import "./Footer.scss";
import {ShieldIcon, Social, StripeTextIcon, SunriseLogoBW, WalletTextIcon} from "assets";
import {Header} from "Components";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Footer() {

	const {t} = useTranslation();

	return (
		<div className="Footer">
			<div className="main">
				<div className="leftContent">
					<SunriseLogoBW/>
					
				</div>

				<div className="mainContent paymentMethods">
					<p className="paymentMehod-container securePayments">
						<ShieldIcon/>
						{t("securePayments")}
					</p>

					<div className="paymentMehod-container paymentMethod wallet">
						<WalletTextIcon/>
					</div>
					<div className="paymentMehod-container paymentMethod">
						<StripeTextIcon/>
					</div>
				</div>

				<div className="rightContent icons-wrapper">
					<SocialLinks/>
				</div>


			</div>
			<div className="subFooter">
				All Rights Reserved © 2023 | AbbaSunrise Tech Inc.
			</div>
		</div>
	);
}

export default Footer;

const SocialLinks = () => {
	return <>
		<Link to="https://www.whatsapp.com/">
			<Social.Whatsapp/>
		</Link>
		<Link to="https://twitter.com/">
			<Social.X/>
		</Link>
		
		<Link to="mailto:hello@hello.com">
			<Social.Email/>
		</Link>
		<Link to="https://instagram.com/">
			<Social.Instagram/>
		</Link>
		<Link to="https://www.facebook.com/">
			<Social.Facebook/>
		</Link>
		<Link to="https://www.youtube.com/">
			<Social.Youtube/>
		</Link>
	</>
}