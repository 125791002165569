
import { ReactComponent as EyeIcon } from "./icons/visibilityIcon.svg";
import { ReactComponent as 	LoginLogo} from "./logos/login-logo.svg";
import { ReactComponent as 	AvatarIllustration} from "./illustrations/avatar-sample.svg";
import { ReactComponent as 	ShoppingCartIcon} from "./icons/shoppingCartIcon.svg";
import { ReactComponent as 	CrossIcon} from "./icons/crossIcon.svg";
import { ReactComponent as ArrowIcon} from "./icons/arrowIcon.svg";
import { ReactComponent as UserFadeIcon} from "./icons/userFadeIcon.svg";
import { ReactComponent as UserFilledIcon} from "./icons/userFilledIcon.svg";
import { ReactComponent as SearchIcon} from "./icons/searchIcon.svg";
import { ReactComponent as CashIcon} from "./icons/cashIcon.svg";
import { ReactComponent as DeliveryIcon} from "./icons/deliveryIcon.svg";
import { ReactComponent as GrowthIcon} from "./icons/growthIcon.svg";
import { ReactComponent as IntegrationIcon} from "./icons/integrationIcon.svg";
import { ReactComponent as MarketingIcon} from "./icons/marketingIcon.svg";
import { ReactComponent as ThinkIcon} from "./icons/thinkIcon.svg";
import { ReactComponent as ShieldIcon} from "./icons/shieldIcon.svg";
import { ReactComponent as WalletTextIcon} from "./icons/e-walletTextIcon.svg";
import { ReactComponent as StripeTextIcon} from "./icons/stripeTextIcon.svg";
import { ReactComponent as SuccessIcon} from "./icons/successIcon.svg";
import { ReactComponent as FailureIcon} from "./icons/failureIcon.svg";

import { ReactComponent as SunriseLogo} from "./logos/abbasunrisetechinc.svg";
import { ReactComponent as SunriseLogoBW} from "./logos/abbasunrisetechincBW.svg";

// Social Icons
import { ReactComponent as EmailIcon} from "./icons/emailIcon.svg";
import { ReactComponent as FacebookIcon} from "./icons/facebookIcon.svg";
import { ReactComponent as InstagramIcon} from "./icons/instagramIcon.svg";
import { ReactComponent as WhatsappIcon} from "./icons/whatsappIcon.svg";
import { ReactComponent as XIcon} from "./icons/xIcon.svg";
import { ReactComponent as YoutubeIcon} from "./icons/youtubeIcon.svg";

import { ReactComponent as Spinner} from "./Spinner.svg";


const Social = {
	Email:EmailIcon,
	Facebook:FacebookIcon,
	Instagram:InstagramIcon,
	Whatsapp:WhatsappIcon,
	X:XIcon,
	Youtube:YoutubeIcon,
};

const UserIcon = {
	Filled: UserFilledIcon,
	Fade: UserFadeIcon
}

const ServicesIcon = {
	Cash: CashIcon,
	Delivery: DeliveryIcon,
	Growth: GrowthIcon,
	Integration: IntegrationIcon,
	Marketing: MarketingIcon,
	Think: ThinkIcon,
}

const StatusIcon = {
	Success: SuccessIcon,
	Failure: FailureIcon

}

export{
	EyeIcon,
	LoginLogo,
	AvatarIllustration,
	ShoppingCartIcon,
	CrossIcon,
	SunriseLogo,
	SunriseLogoBW,
	ArrowIcon,
	Social,
	UserIcon,
	SearchIcon,
	ServicesIcon,
	ShieldIcon,
	WalletTextIcon,
	StripeTextIcon,
	StatusIcon,
	Spinner
}