import axios from "axios"

export const axiosWithAuth = () => {
    return axios.create({
        baseURL:process.env.REACT_APP_API_URL,
        withCredentials:true,
		headers:{
			"X-API-KEY": process.env.REACT_APP_API_KEY
		}
    })
}
