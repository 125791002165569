import {Button, Header, Card} from "Components";
import React from "react";
import "./Home.scss"
import {ServicesIcon, SunriseLogo} from "assets";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";

function Home(){

	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<div className="Home">
			<section className="hero">
				<Header
					title={`ABBASUNRISE`}
					subtitle={"TECH INC."}
				/>
				<div className="row nowrap">
					<p className="description">
						{t("heroInfo")}
					</p>
					<div className="actionGroup">
						<Button
							variant="filled"
							theme="secondary"
							action={()=>{navigate("/products")}}
						>{t("membership")}</Button>
						<Button
							variant="filled"
							theme="secondary"
							action={()=>{navigate("/about")}}
						>{t("moreInfo")}</Button>
					</div>
				</div>
			</section>

			<section className="services">
				<div className="info">
					<Header title={t("services")}/>
					<p className="description">
						{t("subHeroInfo")}
					</p>
					<Button
						action={()=>{navigate("/about")}}
					>{t("moreInfo")}</Button>
				</div>
				<div className="services-wrapper">
					<div className="service-wrapper">
						<div className="foreground">
							<ServicesIcon.Marketing/>
						</div>
					</div>
					<div className="service-wrapper">
						<div className="foreground">
							<ServicesIcon.Cash/>
						</div>
					</div>
					<div className="service-wrapper">
						<div className="foreground">
							<ServicesIcon.Delivery/>
						</div>
					</div>
					<div className="service-wrapper">
						<div className="foreground">
							<ServicesIcon.Growth/>
						</div>
					</div>
					<div className="service-wrapper">
						<div className="foreground">
							<ServicesIcon.Integration/>
						</div>
					</div>
					<div className="service-wrapper">
						<div className="foreground">
							<ServicesIcon.Think/>
						</div>
					</div>
				</div>
			</section>

			{/* <section className="featured">
				<Header title="Destacados" alignText={"center"}/>
				
				<div className="products-wrapper">
					<div className="productPreview">
						<Card>
							<SunriseLogo/>
						</Card>

						<p className="name">
							GiftCard
						</p>

						<p className="price">
							$10
						</p>
					</div>

				</div>



			</section> */}

			{/* <section className="testimonies">
				<Header title="Testimonios" alignText={"center"}/>

				<div className="testimonies-wrapper">
					<div className="testimony">
						<p className="content">
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos, deleniti earum soluta.
						</p>
						<div className="rating">
							* * * * *
						</div>
					</div>
				</div>

			</section> */}
		</div>
	)
}

export default Home;