import React from "react";
import "./About.scss";
import {Button, Card} from "Components";
import {Link} from "react-router-dom";


const imageLinks = [
	"https://abbasunrise-dev-bucket.s3.amazonaws.com/StoreAssets/AboutUs.png",
]

function About() {

	return (
		<div className="About">
			<div className="content">
				{imageLinks.map(link => <div className="img-container" key={link}>
						<img src={link}/> 
					</div>
				)}
			</div>
		</div>
	);
}

export default About;