import React from "react";
import './App.scss';
import {Routes, Route} from "react-router";
import {
	Home,
	Login,
	Products,
	Profile,
	Checkout,
	Product,
	Payment,
	Success,
	Failure,
	OrderStatus,
	Contact,
	Referral,
	Services
} from "Views";
import {Footer, Navigation} from "Components";
import {useAuth, useInitalLoad} from "Context/Auth";
import PrivateRoute from "Utils/PrivateRoute";
import {CartProvider} from "Context/Cart";
import About from "Views/About/About";

function App() {

	const loaded = useInitalLoad();

	if(!loaded){
		return <div className="App">
			loading...
		</div>
	}

	return (
		<div className="App">
			<CartProvider>
				<Navigation/>
			</CartProvider>
				
			<div className="content">
				<Routes>
					<Route exact path="/login" element={
						<Login/>
					}/>
					<Route exact path="/referrer/:referrerCode" element={
						<Referral/>
					}/>
					<Route exact path="/profile/*" element={
						<PrivateRoute>
							<Profile/>
						</PrivateRoute>
					}/>
					<Route exact path="/about" element={
						<About/>
					}/>

					<Route exaxt path="/order/:id/*" element={
						<OrderStatus/>
					}></Route>

					<Route exact path="/checkout" element={
						<CartProvider>
							<Checkout/>		
						</CartProvider>
					}/>

					<Route exact path="/contact" element={
						<Contact/>	
					}/>

					<Route exact path="/products" element={
						<CartProvider>
							<Products/>
						</CartProvider>
					}/>

					<Route exact path="/products/product/:id" element={
						<CartProvider>
							<Product/>
						</CartProvider>
					}/>

					<Route exact path="/payment" element={
						<CartProvider>
							<Payment/>
						</CartProvider>
					}/>

					<Route exact path="/services/health" element={
						<Services.Health/>
					}/>



					<Route exact path="/" element={<Home/>}/>
				</Routes>
				<Footer/>
			</div>			
		</div>
	
	);
}

export default App;