import {useState, createContext, useContext, useEffect} from "react";

const CartContext = createContext();
const CartActionsContext = createContext();

export function useCart() {
	return useContext(CartContext)
}
export function useCartActions() {
	return useContext(CartActionsContext)
}

export function CartProvider ({children}){
	const [cart,setCart] = useState({}); // Logged user

	useEffect(()=>{
		// Fetch local storage
		const savedCart = window.localStorage.getItem("cart")
		if(savedCart){
			setCart(JSON.parse(savedCart))
		}
	},[]);
	
	useEffect(()=>{
		// if(Object.keys(cart).length > 0){
			window.localStorage.setItem("cart",JSON.stringify(cart))
		// }
	},[cart])

	const addToCart = (item) => {
		if(cart[item.id]){
			setQuantity(item.id,cart[item.id].quantity+1);
		}else{
			setCart({...cart,[item.id]:item})
		}
	}

	const setQuantity = (id,quantity) => {
		const newItem = cart[id];
		newItem.quantity = Number(quantity);
		setCart({...cart,[id]:newItem})
	}

	const deleteItem = (id) => {
		const newCart = cart;
		delete newCart[id]
		setCart({...newCart})
	}

	const getTotal = () => {
		return Object.values(cart).reduce((acc, curr) => 
			curr.price * curr.quantity + acc, 0
		)
	}


	return (
		<CartContext.Provider value={cart}>
			<CartActionsContext.Provider value={{
				addToCart,
				deleteItem,
				setQuantity,
				getTotal
			}}>
				{children}
			</CartActionsContext.Provider>
		</CartContext.Provider>
	)


}