import React from "react";
import {formatCurrency} from "Utils/TextFormat";
import "./ProductPreview.scss";
import {Button, Card} from "Components";
import {useCartActions,useCart} from "Context/Cart";


function ProductPreview({
	id,
	name,
	description,
	price,
	imageUrl,
	selectedQuantity
}){
	return (
		<div className="ProductPreview">
			<Card className="productMedia">
				<div className="img-wrapper">
					{imageUrl?
						<img src={imageUrl}/>
					:null}
				</div>
			</Card>
			<div className="productInfo">
				<p className="name">
					{name}
				</p>
				<p className="price">
					{formatCurrency(price)}
				</p>
			</div>

			{selectedQuantity?
				<div className="quantityBadge">
					{selectedQuantity}
				</div>
			:null}
		</div>
	)
}

export default ProductPreview;